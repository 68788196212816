(function (window, $) {
    function startTimer(duration, displayElement, redirectUrl) {
        let timeLeft = duration;
        const timerDiv = displayElement;

        const formatTime = (time) => time < 10 ? `0${time}` : time;

        const updateTimerDisplay = (hours, minutes, seconds) => {
            timerDiv.innerHTML = `
                Your order is reserved for 
                <span>${hours}:${minutes}:${seconds}</span>
            `;
        };

        const timer = setInterval(() => {
            const hours = formatTime(Math.floor(timeLeft / 3600));
            const minutes = formatTime(Math.floor((timeLeft % 3600) / 60));
            const seconds = formatTime(timeLeft % 60);

            updateTimerDisplay(hours, minutes, seconds);

            if (--timeLeft < 0) {
                clearInterval(timer);
                if (redirectUrl) {
                    window.location.href = redirectUrl;
                }
            }
        }, 1000);
    }

    $(document).ready(function() {
        /**
         * Start the timer
         * geting the timer duration from data timer duration attribute
         */
        const timerDiv = document.getElementById('timer');
        if(timerDiv.getAttribute('data-timer-duration')){
            //remove the class d-none of timerDiv and add d-block
            timerDiv.classList.remove('d-none');
            timerDiv.classList.add('d-block');
            const timerDuration = parseInt(timerDiv.getAttribute('data-timer-duration'), 10)*60;
            const redirectUrl = timerDiv.getAttribute('data-sales-page-url');
            startTimer(timerDuration, timerDiv, redirectUrl);
        }
    });

})(window, jQuery);
